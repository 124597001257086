import { eventBus } from "helpers/eventBus";
import { useEffect } from "react";

const useDisableTransitions = () => {
    useEffect(() => {
        const handleDisableTransitions = () => {
            document.body.classList.add("disable-transitions");

            // Remove the class after a short delay to re-enable transitions
            setTimeout(() => {
                document.body.classList.remove("disable-transitions");
            }, 300); // Adjust the delay as needed
        };

        // Events that may trigger transition disabling
        eventBus.on("toggleInteractiveMode", handleDisableTransitions);
        window.addEventListener("orientationchange", handleDisableTransitions);

        return () => {
            eventBus.off("toggleInteractiveMode", handleDisableTransitions);
            window.removeEventListener(
                "orientationchange",
                handleDisableTransitions
            );
        };
    }, []);
};

export default useDisableTransitions;
